// import logo from './logo.svg';
import './App.css';

import "./carousel.css";
import "./floating.css"
// import "./scrollEffect.css";
// import "./defaultScrollEffect.css";

import "./clients.css";
import "./scrollAnimation.css";

// import "./clientsCards.css"

import "./imageSlider.css"

import "./carouselHorizontal.css";

import "./navBarTop.css";
import "./navBarBottom.css";

import React, { useEffect, useRef, useState } from 'react';

function ScrollAnimation() {

  useEffect(() => {


    const boxes = document.querySelectorAll(".box");


    console.log("boxes", boxes)

    window.addEventListener("scroll", checkBoxes);


    checkBoxes();



    function checkBoxes() {
      const triggerBottom = (window.innerHeight / 5) * 4;

      boxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top;
        //  const boxBottom = box.getBoundingClientRect().bottom; // testing things out. Don't use this.
        //  console.log("boxTop = ", boxTop, "triggerBottom = ", triggerBottom);

        if (boxTop < triggerBottom) {
          box.classList.add("show");
        }
        else {
          // console.log("Removing show on scroll animation.")
          box.classList.remove("show");
        }
      });
    }

  }, []);


  return (
    <>

    </>
  );
}

function App() {
  return (
    <>
      {/* <Background /> */}
      <NavBarTop />
      <Floating />
      <Slider />
      <Credentials />
      <Clients />
      {/* <Contact /> */}
    </>

  );
}

function NavBarTop() {

  useEffect(() => {

    function checkScrollTop() {
      if (window.scrollY >= stickyTop) {
        navbarTop.classList.add("stickyTop")
        navbarBottom.classList.add("stickyBottom")
      } else {
        navbarTop.classList.remove("stickyTop");
        navbarBottom.classList.remove("stickyBottom");
      }
    }

    window.onscroll = function () { checkScrollTop() };

    var navbarTop = document.getElementById("navbarTop");
    var stickyTop = navbarTop.offsetTop;

    var navbarBottom = document.getElementById("navbarBottom");


  }, []);




  return (
    <>
      {/* navbar TOP */}
      <div id="navbarTop">
        <p className="navbarTopPara">Stephen Anderson</p>
        <p className="navbarTopPara">Full-Stack Web Developer</p>
        <a class="active navBarLinkRight" href="mailto:Steve@andersonwebmarketing.ca">Steve@andersonwebmarketing.ca</a>
      </div>

      {/* navbar Bottom */}
      <div id="navbarBottom">

        <a href="https://github.com/HifiAudi0" target="_blank" rel="noreferrer">
          <img id="githubIcon" className="contactIcon" src="./img/github-icon-v3.png" alt="A GitHub Icon that links to my GitHub profile." /></a>
        <h3 className="navbarBottomHeading navbarBottomLeftSideText">Let's Connect!</h3>
        <h3 className="navbarBottomHeading navbarBottomRightSideText">Updated in 2024</h3>
        <a href="https://www.linkedin.com/in/stephenanderson-587/" target="_blank" rel="noreferrer">
          <img id="linkedinIcon" className="contactIcon" src="./img/linkedIn-icon.png" alt="A LinkedIn Icon that links to my LinkedIn profile." /></a>
        {/* <a href="mailto:stephen.and@outlook.com"><img id="mailIcon" className="contactIcon" src="./img/mail.png" alt="" /></a> */}

      </div >

    </>
  );

}


function Background() {
  return (
    <>
      <div className="wrapper">
        <video id="background-video" playsInline autoPlay loop muted>
          <source id="video-src" src="./img/codeing-bg-v2.mov" type="video/mp4">
          </source>
          Did not load video.
        </video>
      </div>

    </>
  );
}

function Clients() {
  // const scrollRef = useRef(null)


  // clientsCards
  /*
  useEffect(() => {

    const cards = document.querySelectorAll('.card');

    cards.forEach((card) => {
      card.addEventListener('click', () => {
        if (!card.hasAttribute('active')) {
          updateActiveCard(card);
        }
      });
    });

    function updateActiveCard(activeCard) {
      cards.forEach((card) => {
        if (card === activeCard) {
          card.setAttribute('active', '');
        } else {
          card.removeAttribute('active');
        }
      })
    }

  }, []);
  */
  // clientsCards

  const [whichClientClicked, setWhichClientClicked] = useState("One");

  // Ref to store the previous value of `whichClientClicked`
  const prevWhichClientClicked = useRef(whichClientClicked);

  // Effect to handle changes to `whichClientClicked`
  useEffect(() => {

    function removeClientTwoAddClientOne() {
      document.getElementById("slide1").classList.remove("clientTwoSlide1");
      document.getElementById("slide1").classList.add("clientOneSlide1");
      document.getElementById("slide2").classList.remove("clientTwoSlide2");
      document.getElementById("slide2").classList.add("clientOneSlide2");
      document.getElementById("slide3").classList.add("clientOneSlide3");
      document.getElementById("slide4").classList.add("clientOneSlide4");
      document.getElementById("slide5").classList.add("clientOneSlide5");
    }
    function removeClientOneAddClientTwo() {
      document.getElementById("slide1").classList.remove("clientOneSlide1");
      document.getElementById("slide1").classList.add("clientTwoSlide1");
      document.getElementById("slide2").classList.remove("clientOneSlide2");
      document.getElementById("slide2").classList.add("clientTwoSlide2");
      document.getElementById("slide3").classList.remove("clientOneSlide3");
      document.getElementById("slide4").classList.remove("clientOneSlide4");
      document.getElementById("slide5").classList.remove("clientOneSlide5");
    }
    function showImages() {
      document.getElementById("wrap").classList.remove("hide");
      document.getElementById("wrap").classList.add("show");
    }

    function hideImages() {
      document.getElementById("wrap").classList.remove("show");
      document.getElementById("wrap").classList.add("hide");
    }

    function showVideo() {
      document.getElementById("clientThreeVideo").classList.remove("hide");
      document.getElementById("clientThreeVideo").classList.add("show");
    }

    function hideVideo() {
      document.getElementById("clientThreeVideo").classList.remove("show");
      document.getElementById("clientThreeVideo").classList.add("hide");
    }

    if (whichClientClicked === "Two" && prevWhichClientClicked.current === "One") {
      removeClientOneAddClientTwo();
      console.log("11111111111 old client=", prevWhichClientClicked.current, "new client =", whichClientClicked);
    } else if (whichClientClicked === "One" && prevWhichClientClicked.current === "Two") {
      removeClientTwoAddClientOne();
      console.log("2222222222 old client=", prevWhichClientClicked.current, "new client =", whichClientClicked);
    } else if (whichClientClicked === "One" && prevWhichClientClicked.current === "Three") {
      console.log("3333333333 SCENARIO");
      removeClientTwoAddClientOne(); // Might create a bug in certain conditions. Appears there is no bug. Just out of caution if
      // one appears we need to re-evaluate this line
      hideVideo()
      showImages();
    }
    else if (whichClientClicked === "Three" && prevWhichClientClicked.current === "Two") {
      console.log("44444444444 SCENARIO");
      hideImages();
      showVideo();
    }
    else if (whichClientClicked === "Three" && prevWhichClientClicked.current === "One") {
      console.log("55555555555 SCENARIO");
      hideImages();
      showVideo();
    }
    else if (whichClientClicked === "Two" && prevWhichClientClicked.current === "Three") {
      console.log("6666666666666 SCENARIO");
      hideVideo()
      showImages();
      removeClientOneAddClientTwo();
    }
    else {
      console.log("OOOOps client condition not true.");
    }

    // After handling the logic, update the ref to hold the current value of `whichClientClicked`
    prevWhichClientClicked.current = whichClientClicked;
  }, [whichClientClicked]); // Runs whenever `whichClientClicked` changes

  // Setup event listeners
  useEffect(() => {
    const handleClientOneClick = () => {

      setWhichClientClicked("One");
    };

    const handleClientTwoClick = () => {

      setWhichClientClicked("Two");
    };

    const handleClientThreeClick = () => {

      setWhichClientClicked("Three");
    };

    const clientOneElement = document.querySelector("#clientOne");
    const clientTwoElement = document.querySelector("#clientTwo");
    const clientThreeElement = document.querySelector("#clientThree");

    clientOneElement?.addEventListener("click", handleClientOneClick);
    clientTwoElement?.addEventListener("click", handleClientTwoClick);
    clientThreeElement?.addEventListener("click", handleClientThreeClick);

    // Cleanup event listeners
    return () => {
      clientOneElement?.removeEventListener("click", handleClientOneClick);
      clientTwoElement?.removeEventListener("click", handleClientTwoClick);
      clientThreeElement?.removeEventListener("click", handleClientThreeClick);
    };
  }, []);

  useEffect(() => {

    // var clientOne = document.getElementById("clientOne")



    // document.addEventListener("click", () => {
    //   document.getElementById("clientOneSlide1").classList.add("clientTwoSlide1")
    //   document.getElementById("clientOneSlide1").classList.remove("clientOneSlide1")

    // })



    var clientOneSliderImages = document.querySelectorAll(".clientOneSlide"),
      clientOneArrowLeft = document.querySelector("#clientOneArrowLeft"),
      clientOneArrowRight = document.querySelector("#clientOneArrowRight"),
      clientOneCurrent = 0;

    // Clear all images
    function reset(whichSection) {
      if (whichSection === "clientOne") {
        for (let i = 0; i < clientOneSliderImages.length; i++) {
          clientOneSliderImages[i].style.display = "none";
        }
      }
    }

    // Init slider
    function startSlide() {
      reset();
      clientOneSliderImages[0].style.display = "block";
    }

    // Show prev
    function slideLeft(whichSection) {
      reset();
      if (whichSection === "clientOne") {
        clientOneSliderImages[clientOneCurrent - 1].style.display = "block";
        clientOneCurrent--;
      }
    }

    // Show next
    function slideRight(whichSection) {
      reset(whichSection);
      if (whichSection === "clientOne") {
        clientOneSliderImages[clientOneCurrent + 1].style.display = "block";
        clientOneCurrent++;
      }
    }


    clientOneArrowLeft.addEventListener("click", function () {
      if (clientOneCurrent === 0) clientOneCurrent = clientOneSliderImages.length;
      slideLeft("clientOne");
    });


    clientOneArrowRight.addEventListener("click", function () {
      if (clientOneCurrent === clientOneSliderImages.length - 1) clientOneCurrent = -1;
      slideRight("clientOne");
    });

    startSlide();

  }, []);


  return (
    <>
      <div className="scrollAnimation">

        <ScrollAnimation />

        <div className="box">

          <div className="pageMainHeading"><h2 className="pageMainHeadingText">CLIENTS</h2></div>

        </div>

        <div className="clients">
          {/* <td align="center" valign="center" /><div class="gradientLine btn-gradient-1 vertical"></div> */}
          <td align="center" valign="center" /><div class="clientsLine  horizontal"></div>
          <a className="clientOne" id="clientOne">
            <img className="clientImage clientOneImage" src="./img/monthomes-logo.png" alt="The Monteiro Homes logo" />
          </a>
          <a className="clientTwo" id="clientTwo">
            <img className="clientImage clientTwoImage" src="./img/swearfreevideos-logo.png" alt="The Swear Free Videos logo" />
          </a>
          <a className="clientThree" id="clientThree">
            <img className="clientImage clientThreeImage" src="./img/productReviewsLogo.png" alt="The Product Reviews logo" />
          </a>
        </div>
        <br />    <br />    <br />    <br />    <br />
        <div className="box">
          <div class="wrap show" id="wrap">
            <div id="clientOneArrowLeft" class="arrow arrowLeft"></div>
            <div id="slider">
              <div class="clientOneSlide clientOneSlide1 slide" id="slide1">
                <div class="slide-content">

                </div>
              </div>
              <div class="clientOneSlide clientOneSlide2 slide" id="slide2">
                <div class="slide-content">

                </div>
              </div>
              <div class="clientOneSlide clientOneSlide3 slide" id="slide3">
                <div class="slide-content">

                </div>
              </div>
              <div class="clientOneSlide clientOneSlide4 slide" id="slide4" >
                <div class="slide-content">

                </div>
              </div>
              <div class="clientOneSlide clientOneSlide5 slide" id="slide5">
                <div class="slide-content">

                </div>
              </div>
            </div>
            <div id="clientOneArrowRight" class="arrow arrowRight"></div>
          </div>
        </div>


        <div className="productReviewDemoVideo hide" id="clientThreeVideo">
          <iframe className="productReviewVideoIframe" src="https://www.youtube.com/embed/-cquqAXgIyk?si=jEdWXiA9EnOShDfg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>



      </div>
      <br />
      {/* <img src="./img/boxcard-v5.png" className="backgroundBoxCard" />
            <img src="./img/3dbox.png" className="threeDboximage" />
            <h3 className="clientOneDescription">Responsive design.<br /> Mobile first approach.<br />Custom video background. <br />Custom built website.</h3> */}


      {/* <h1 className="experiencedIn header">Monteiro Homes</h1>
              <div className="sliderHorizontal">
                <div className="slide-track-horizontal">
                  <div className="slideHorizontal">
                    <p className="scrollHorizontalText">&#x2022; Responsive design.<br />
                      &#x2022; Mobile first approach.<br />
                      &#x2022; Custom built.<br />
                      &#x2022; No automated website builders.<br />
                    </p>
                  </div>
                  <div className="slideHorizontal">
                    <p className="scrollHorizontalText">&#x2022; Mobile first approach.<br /></p>
                  </div>
                </div>
              </div> */}


      {/* <div className="clientsWrapper">
        <h3 className="header clientsPara">Sepheron Gaming. Responsive design. Mobile first approach.<br />
          Custom animations. Custom video background.</h3>
        <img className="clientsHolder" src="./img/sepheron-screenshot-mobile.png" />
 
        <h3 className="header clientsPara">URL: www.sepheron.net<br /></h3>
      </div> */}

      {/* <h1 className="experiencedIn header">EXPERIENCED IN:</h1> */}






      {/* <img className="clientsHolder" src="./img/swearFreeVideos-screenshot-v2.png" /> */}
      {/* <p className="clientOneText">Responsive design. Mobile first approach.</p> */}
      {/* <h3 id="clientsUrl" className="clientsPara clientsUrl">URL: <a target="_blank" className="clientsUrlLink" href="https://www.swearfreevideo.com">www.swearfreevideos.com</a><br /></h3><br /><br /><br /> */}
      {/* <div className="clientsWrapper"> */}
      {/* 
      <h3 className="clientsPara clientsUrl">URL: <a target="_blank" className="clientsUrlLink" href="https://www.monteirohomes.com" >www.monteirohomes.com</a><br /></h3><br /><br /> */}
      {/* <img className="clientsHolder" src="./img/swearFreeVideos-screenshot-v2.png" /> */}

      {/* <h3 id="clientsUrl" className="clientsPara clientsUrl">URL: <a target="_blank" className="clientsUrlLink" href="https://www.swearfreevideo.com">www.swearfreevideos.com</a><br /></h3><br /><br /><br /> */}


      {/* <div className="clientOne">
            <img className="threedbox" src="./img/3dbox-v5.png" />

            <h3 className="clientsHeading" id="clientOneHeading">Swear Free Videos<br />
              Reducing swear in online videos.</h3>
            <p id="clientsOnePara" className="clientsPara">&#x2022; Responsive design.<br />&#x2022; Mobile first approach.<br />
              &#x2022; Custom back-end code in Node.js and Express.</p>


          </div>



          <button className="clientsUrlLinkBtn gradient btn"><a target="_blank" href="https://www.swearfreevideos.com">Visit site</a></button>
        </div>


        <div className="box">

          <h3 className="clientsHeading" className="header">Monteiro Homes <br />A local Canadian home contractor.</h3> <p className="clientsPara">Responsive design. Mobile first approach.<br />Custom video background. Custom built website.</p>
          <img id="clientsImgThree" className="clientsHolder" src="./img/monteirohomes-screenshot-v3.png" />



          <button className="clientsUrlLinkBtn gradient btn"><a target="_blank" href="https://www.monteirohomes.com">Visit site</a></button>
        </div>

        <div className="box">
          <h3 className="clientsHeading" className="header">A.I. Powered - Product Review Lookup<br /></h3 >

          <p className="clientsPara">Automatic detection of model numbers inside an image and corresponding product review page lookup.<br />Backend project with Node.js and Express. Powered By A.I.<br />This website is not intended for public usage and is therefore not public at this time.</p >

          <div className="productReviewDemoVideo">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/-cquqAXgIyk?si=jEdWXiA9EnOShDfg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        */}



      {/* <p className="header clientsPara">Sepheron Gaming.<br /> Responsive design. Mobile first approach.<br />
        Custom animations. Custom video background.</p>
      <img id="clientsImgOne" className="clientsHolder" src="./img/sepheron-screenshot-mobile.png" />
      <br /><br /><br /><br />
      <h3 className="header clientsPara clientsUrl">URL: www.sepheron.net<br /></h3>
      <br /><br /><br /><br /> */}

      {/* <iframe className="iframe" src="https://swearfreevideos.com">

      </iframe> */}


    </>
  );
}

function Credentials() {
  return (
    <>

      <div className="scrollAnimation" >

        <ScrollAnimation />


        <div className="box">

          <div className="pageMainHeading"><h2 className="pageMainHeadingText">CREDENTIALS</h2></div>
        </div>
        {/* <p className="header">
        <img className="graduationImg" src="./img/graduation-4.png" />in Marketing from McMaster University<br />
        <img className="graduationImg" src="./img/graduation-3.png" />in Web Design from McMaster University<br />

        <img className="graduationImg" src="./img/graduation-4.png" />in Digital Marketing from McMaster University<br />

        <img className="graduationImg" src="./img/graduation-3.png" />in Supply Chain Management from George Brown<br />
      </p> */}
        <div className="box">

          <div className="logoCenter" >
            <img className="logoMain" src="./img/andersonLogo.png" alt="The Anderson Web Marketing logo" />
          </div>
        </div>
        <br /><br /><br /><br />

        {/* <td align="center" valign="center" /><div class="gradientLine btn-gradient-1 vertical"></div>
          <td align="center" valign="center" /><div class="gradientLine btn-gradient-1 horizontal"></div> */}

        <div className="box">
          {/* <div class="gradient-border">

          </div> */}


          <div class="credentialsCard credentialsCardLeftSide">
            <div class="credentials-card-container">
              <h1>Diploma</h1>
              <h3>In Full-Stack Web Developer</h3>
              <h3>From McMaster University</h3>
            </div>
          </div>

          <div class="credentialsCard credentialsCardLeftSide">
            <div class="credentials-card-container" id="diffColor" >
              <h1>Diploma</h1>
              <h3>In Marketing</h3>
              <h3>From McMaster University</h3>
            </div>
          </div>

          <div className="credentialsContainerRightSide">
            <div class="credentialsCard credentialsCardRightSide">
              <div class="credentials-card-container">
                <h1>Certification</h1>
                <h3>In Web Design</h3>
                <h3>From McMaster University</h3>
              </div>
            </div>

            <div class="credentialsCard credentialsCardRightSide">
              <div class="credentials-card-container" id="diffColor">
                <h1>Certification</h1>
                <h3>In Digital Marketing</h3>
                <h3>From McMaster University</h3>
              </div>
            </div>

            <div class="credentialsCard credentialsCardRightSide">
              <div class="credentials-card-container">
                <h1>Certification</h1>
                <h3>In Cloud Computing</h3>
                <h3>From University of Toronto</h3>
              </div>
            </div>

            <div class="credentialsCard credentialsCardRightSide" >
              <div class="credentials-card-container" id="diffColor">
                <h1>Certification</h1>
                <h3>In Supply Chain Management</h3>
                <h3>From George Brown</h3>

              </div>

            </div>

          </div>

        </div>
      </div>
      {
          /* OLD CREDENTIALS 
          <div class="credentialsParentWrapper">
            <p className="header">

              <img className="credentialsCardBg rounded-shadow" src="./img/cards.png" />

              <img id="leftImg" className="graduationImg" src="./img/graduation-4.png" />
              <p id="leftText" className="credentialsBodyText topPara">&#x2022;&nbsp;&nbsp; Diploma in Full Stack Web Developer from McMaster University</p>

              <br />

              <p id="leftText" className="credentialsBodyText middlePara">&#x2022;&nbsp;&nbsp; Diploma in Marketing from McMaster University</p>
              <br />

              <img id="rightImg" className="graduationImg" src="./img/graduation-5.png" />
              <p id="rightText" className="credentialsBodyText topPara">&#x2022;&nbsp;&nbsp; Certification in Web Design from McMaster University</p>
              <br />

              <p id="leftText" className="credentialsBodyText bottomPara">&#x2022;&nbsp;&nbsp; Certification in Digital Marketing from McMaster University</p>
              <br />

              <p id="rightText" className="credentialsBodyText middlePara">&#x2022;&nbsp;&nbsp; Certification in Cloud Computing from University Of Toronto</p>

              <p id="rightText" className="credentialsBodyText bottomPara">&#x2022;&nbsp;&nbsp; Certification in Supply Chain Management from George Brown</p>
              <br />
            </p>
          </div> */}
    </>
  );
}

function Slider() {
  return (
    <>
      <div className="scrollAnimation">

        <ScrollAnimation />


        <div className="box">

          <h1 className="experiencedIn header">MOST EXPERIENCED IN:</h1>
          <div className="slider">
            <div className="slide-track">
              <div className="slide">
                <img src="./img/html-icon-2.png" height="80" width="50" alt="" />
              </div>
              <div className="slide">
                <img src="./img/css-icon-2.png" height="80" width="50" alt="" />
              </div>
              <div className="slide">
                <img src="./img/js-icon-2.png" height="80" width="50" alt="" />
              </div>
              <div className="slide">
                <img src="./img/react-icon-3.png" height="80" width="50" alt="" />
              </div>
              <div className="slide">
                <img src="./img/node-icon-2.png" height="80" width="150" alt="" />
              </div>
              <div className="slide">
                <img id="sliderGithubIcon" src="./img/github-icon-2.png" height="80" width="80" alt="" />
              </div>
              <div className="slide">
                <img id="sliderGithubIcon" src="./img/mysql-icon-v2.png" height="80" width="80" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Floating() {
  return (
    <>
      <div className="scrollAnimation">

        <ScrollAnimation />


        <div className="box">

          <div className="container">
            <div className="avatar">
              <img src="./img/animated-me.png" alt="A.I. generated pic of myself." />
            </div>
            <h1 className="header">Hi, I'm Stephen Anderson.<br />A <span className="purpleBlueGradient">Full Stack Web Developer.</span></h1>
          </div>
        </div>
      </div>
    </>
  )
}

// function Contact() {
//   return (
//     <>

//       <div className="scrollAnimation">

//         <ScrollAnimation />


//         <div className="box">

//           <h1 className="header">CONTACT</h1>



//           <img id="contactBgImage" className="credentialsCardBg rounded-shadow" src="./img/cards.png" />

//           <a href="https://www.linkedin.com/in/stephenanderson-587/" target="_blank"><img id="githubIcon" className="contactIcon" src="./img/github-icon-v3.png" alt="" /></a>
//           <a href="https://www.linkedin.com/in/stephenanderson-587/" target="_blank"><img id="linkedinIcon" className="contactIcon" src="./img/linkedIn-icon.png" alt="" /></a>
//           <a href="mailto:stephen.and@outlook.com"><img id="mailIcon" className="contactIcon" src="./img/mail.png" alt="" /></a>

//           <img className="logo" src="./img/andersonLogo.png" />

//         </div>
//       </div>
//       <br /><br />
//     </>
//   );
// }

export default App;
